/* Footer Styles */
.footer {
  padding: 40px 0; /* Adjusted padding for better balance */
  background: #282c34; /* Dark background for a modern look */
  color: #fff;
  position: relative;
  overflow: hidden;
  text-align: center;
  border-top: 5px solid #61dafb; /* Bold border to separate the footer */
}

/* Gradient Overlay for Footer */
.footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at top left, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4));
  opacity: 0.5;
  z-index: -1;
}

/* Flexbox Layout for Footer */
.footer-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Ensure responsiveness */
  gap: 20px; /* Space between columns */
}

/* Footer Column */
.footer-col {
  flex: 1 1 calc(33.333% - 20px); /* Equal width columns with space for gaps */
  max-width: calc(33.333% - 20px); /* Ensure columns do not exceed 1/3 of the container width */
  padding: 20px; /* Adjusted padding */
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.4); /* Slightly transparent background */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3); /* Shadow for a lifted effect */
  box-sizing: border-box; /* Ensure padding is included in width calculation */
  margin-bottom: 20px; /* Space between rows */
}

/* Footer Title */
.footer-title {
  font-size: 1.5rem; /* Adjusted font size */
  color: #61dafb; /* Highlight color */
  margin-bottom: 10px; /* Reduced margin */
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  padding-bottom: 6px;
}

/* Underline Animation for Titles */
.footer-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #61dafb;
  transition: width 0.3s ease;
}

.footer-title:hover::after {
  width: 100%;
}

/* Footer Text */
.footer-text {
  font-size: 0.9rem; /* Smaller font size for compactness */
  margin-bottom: 8px; /* Reduced margin for compactness */
  line-height: 1.4; /* Tighten line height */
  color: #ccc; /* Lighter text color for contrast */
}

/* Footer Links */
.footer-link {
  color: #ffffff; /* White links */
  text-decoration: none;
  margin-bottom: 6px; /* Reduced margin for compactness */
  display: block;
  transition: color 0.3s, text-shadow 0.3s, transform 0.3s;
}

/* Footer Link Hover State */
.footer-link:hover {
  color: #61dafb; /* Highlight color on hover */
  text-decoration: underline;
  text-shadow: 0 0 5px rgba(97, 218, 251, 0.6);
  transform: translateX(3px); /* Subtle movement effect */
}

/* Social Icons Container */
.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px; /* Adjusted spacing for a more compact layout */
  margin-top: 10px; /* Reduced margin for compactness */
}

/* Social Icon Style */
.social-icon {
  font-size: 1.8rem; /* Adjusted icon size */
  color: #61dafb; /* Icon color */
  transition: color 0.3s, transform 0.3s, box-shadow 0.3s;
  border-radius: 50%;
  padding: 6px; /* Adjusted padding */
  background: rgba(255, 255, 255, 0.1); /* Light background for icons */
}

/* Social Icon Hover State */
.social-icon:hover {
  color: #fff; /* Change color on hover */
  transform: scale(1.2);
  background: rgba(255, 255, 255, 0.2); /* Light background on hover */
  box-shadow: 0 0 10px rgba(97, 218, 251, 0.5); /* Glow effect */
}

/* Footer Bottom Section */
.footer-bottom {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #61dafb; /* Thin border to separate from content */
  background: rgba(0, 0, 0, 0.6); /* Slightly darker background */
  width: 100%; /* Ensure it spans the full width */
  position: relative; /* Allow positioning of child elements */
}

.footer-bottom .text-center {
  display: flex;
  justify-content: center; /* Center content horizontally */
}

.footer-bottom .footer-text {
  font-size: 0.8rem; /* Smaller font size for footer bottom text */
  color: #fff; /* White text color for contrast */
  margin: 0; /* Remove default margin */
}

/* Responsive Design for Footer Bottom */
@media (max-width: 768px) {
  .footer-bottom {
    padding-top: 15px; /* Adjust padding for smaller screens */
  }
}


/* Responsive Design */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-col {
    max-width: 100%;
    margin-bottom: 20px; /* Adjust spacing for smaller screens */
    padding: 15px; /* Consistent padding */
  }

  .social-icons {
    gap: 15px; /* Adjust spacing for mobile */
  }
}
