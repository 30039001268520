/* src/components/Portfolio.module.css */

.container {
  padding: 0 15px;
}

.projectCard {
  border: none;
  border-radius: 12px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  transition: transform 0.4s ease, box-shadow 0.4s ease, background-color 0.4s ease;
  overflow: hidden;
  background-color: #fff;
  background: linear-gradient(white, #f9f9f9);
}

.projectCard:hover {
  transform: translateY(-20px);
  box-shadow: 0 24px 48px rgba(0, 0, 0, 0.2);
  background-color: #f0f0f0;
}

.projectCard img {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  max-height: 280px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.projectCard img:hover {
  transform: scale(1.08);
}

.card-body {
  padding: 20px;
}

.cardTitle {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 15px;
  color: #333;
}

.cardText {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
}

.details {
  margin: 15px 0;
}

.list {
  padding-left: 20px;
  list-style-type: disc;
}

.list li {
  margin-bottom: 10px;
}

.link {
  color: #007bff;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
  color: #0056b3;
}

.textCenter {
  font-size: 3rem;
  font-weight: 800;
  color: #222;
  margin-bottom: 4rem;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.mt-5 {
  margin-top: 5rem;
}

.mb-4 {
  margin-bottom: 4rem;
}
