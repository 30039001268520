/* src/components/Blog.css */
.blog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 1200px; /* Restricting the maximum width for better readability */
    margin: auto; /* Center the container */
    width: 100%; /* Use full width of the viewport */
  }
  
  .news-card {
    display: flex;
    flex-direction: row; /* Align image and content horizontally */
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 15px 0; /* Add vertical spacing between cards */
    padding: 15px;
    width: 100%;
    max-width: 1200px; /* Set a maximum width for the card */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adding shadow for a 3D effect */
    background-color: #fff; /* White background for better contrast */
  }
  
  .news-image {
    width: 150px; /* Fixed small width for the image */
    height: 100px; /* Fixed height for the image */
    object-fit: cover; /* Crop and fill the image */
    border-radius: 8px;
    margin-right: 15px; /* Space between image and content */
  }
  
  .news-content {
    flex: 1; /* Allow content to take up the remaining space */
  }
  
  .news-title {
    font-size: 1.5em;
    margin: 0 0 10px;
    color: #333; /* Darker color for better readability */
  }
  
  .news-description {
    margin: 0 0 15px;
    color: #666; /* Lighter color for description */
  }
  
  .news-link {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .news-link:hover {
    text-decoration: underline;
  }
  
  .loading, .error {
    font-size: 1.2em;
    color: #333;
    margin: 20px;
  }
  
  .no-news {
    font-size: 1.2em;
    color: #888;
    margin: 20px;
  }
  