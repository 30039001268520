/* Hero Section */
.hero-section {
  background-color: #007bff; /* Fallback color */
  background: url('../assets/images/heroPic.jpg') no-repeat center center;
  background-size: cover;
  color: white;
  padding: 40px 0; /* Smaller padding */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  overflow: hidden;
  height: 60vh; /* Smaller height for a more compact look */
  border-bottom: 5px solid #0056b3;
}

/* Gradient Overlay */
.hero-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.6) 100%);
  z-index: 1;
}

/* Hero Image with Circular Shape */
.hero-image-container {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Container for Hero Content */
.hero-container {
  position: relative;
  z-index: 2;
  max-width: 90%;
}

/* Hero Title */
.hero-title {
  font-size: 2.5em; /* Adjust font size */
  margin-bottom: 10px;
  font-weight: 800;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
  animation: fadeInDown 1s ease-out;
  color: #fff;
}

/* Hero Subtitle */
.hero-subtitle {
  font-size: 1.5em; /* Adjust font size */
  margin-bottom: 15px;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
  animation: fadeInUp 1s ease-out;
  color: #e0e0e0;
}

/* Hero Button */
.hero-button {
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 20px;
  background-color: #0056b3;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  z-index: 2;
  border: none;
}

.hero-button:hover {
  background-color: #004494;
  transform: translateY(-3px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
}

/* Button Focus Effect */
.hero-button:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

/* Additional Call-to-Action */
.hero-cta {
  margin-top: 20px;
  font-size: 1.2em;
  color: rgba(255, 255, 255, 0.9);
}

.hero-cta a {
  color: #00d2d3;
  text-decoration: underline;
}

.hero-cta a:hover {
  color: #007bff;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .hero-title {
    font-size: 1.8em;
  }

  .hero-subtitle {
    font-size: 1.2em;
  }

  .hero-button {
    padding: 8px 16px;
    font-size: 0.9em;
  }

  .hero-section {
    padding: 40px 0;
    height: 50vh;
  }

  .hero-image-container {
    width: 120px;
    height: 120px;
  }
}

/* Keyframe Animations */
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Info Cards */
.info-card {
  border: none;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  border-radius: 12px;
  background: #ffffff;
  position: relative;
}

/* Info Card Top Border */
.info-card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(90deg, #007bff, #00d2d3);
  z-index: 1;
}

/* Info Card Hover Effect */
.info-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
}

/* Card Body Position */
.info-card .card-body {
  position: relative;
  z-index: 2;
}

/* Card Title */
.card-title {
  font-size: 1.6em;
  margin-bottom: 15px;
  font-weight: 700;
  color: #007bff;
}

/* Card Text */
.card-text {
  font-size: 1.2em;
  color: #555;
  margin-bottom: 15px;
}

/* Card Image */
.card-img-top {
  height: 220px;
  object-fit: cover;
  border-bottom: 2px solid #ddd;
  transition: transform 0.3s ease;
}

/* Card Image Hover Effect */
.info-card:hover .card-img-top {
  transform: scale(1.05);
}

/* Spacing and Layout */
.container {
  max-width: 1140px;
}

.mt-5 {
  margin-top: 4rem !important;
}

.mb-4 {
  margin-bottom: 2rem !important;
}

/* Keyframe Animations */
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
