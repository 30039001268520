/* src/components/NavBar.css */

/* Style for Navbar links */
.nav-link {
    color: #ffffff !important; /* Ensure links are white */
    font-size: 1.1em; /* Slightly larger font size for better readability */
    margin-left: 1rem; /* Space between links */
    position: relative; /* For pseudo-element positioning */
    transition: all 0.3s ease; /* Smooth transition effects */
  }
  
  .nav-link:hover {
    color: #61dafb !important; /* Change color on hover */
    text-decoration: none; /* Remove underline on hover */
  }
  
  /* Add a subtle glow effect on hover */
  .nav-link::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: #61dafb;
    transform: scaleX(0);
    transition: transform 0.3s ease;
    transform-origin: bottom right;
  }
  
  .nav-link:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  
  /* Highlight active link */
  .nav-link.active {
    color: #61dafb !important; /* Change color for active link */
    font-weight: bold; /* Bold font for active link */
  }
  
  /* Customize Navbar brand */
  .navbar-brand {
    font-weight: bold;
    font-size: 1.5em; /* Larger font size for the brand name */
    transition: color 0.3s ease; /* Smooth color transition */
  }
  
  .navbar-brand:hover {
    color: #61dafb; /* Change color on hover */
  }
  
  /* Adjust Navbar toggle button */
  .navbar-toggler {
    border-color: #61dafb; /* Custom border color for the toggle button */
  }
  
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='%23ffffff' stroke-width='2' d='M5 7h20M5 15h20M5 23h20'/%3E%3C/svg%3E");
  }
  
  /* Navbar background color with gradient */
  .navbar {
    background: linear-gradient(135deg, #333, #555); /* Dark gradient background */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
  }
  
  /* Add a smooth transition effect to Navbar */
  .navbar-collapse {
    transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth collapse and expand effect */
  }
  
  /* Ensure proper alignment of nav links */
  .navbar-nav {
    margin-left: auto; /* Push nav links to the right */
  }
  
  .navbar-collapse {
    flex-grow: 1; /* Allow collapse to grow */
    display: flex; /* Flexbox layout */
    justify-content: flex-end; /* Align items to the end of the container */
  }
  